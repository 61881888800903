import { SVLRouteValue } from '@domain/shared/svl-route-value';
import { AppRouteNames } from 'src/app/app.routenames';

interface CoreRouteNamesType {
  users: SVLRouteValue;
  variables: SVLRouteValue;
  categories: SVLRouteValue;
  platformContracts: SVLRouteValue;
  utilitiesFaq: SVLRouteValue;
  customers: SVLRouteValue;
  contractGenerated: SVLRouteValue;
}

export const CoreRouteNames: CoreRouteNamesType = {
  users: { local: 'users', global: AppRouteNames.core + '/users' },
  variables: { local: 'variables', global: AppRouteNames.core + '/variables' },
  categories: {
    local: 'categories',
    global: AppRouteNames.core + '/categories',
  },
  platformContracts: {
    local: 'platform-contracts',
    global: AppRouteNames.core + '/platform-contracts',
  },
  utilitiesFaq: {
    local: 'utilities-faq',
    global: AppRouteNames.core + '/utilities-faq',
  },
  customers: { local: 'customers', global: AppRouteNames.core + '/customers' },
  contractGenerated: { local: 'contract-generated', global: AppRouteNames.core + '/contract-generated' },
};
